import React from "react";
import DrawArea from "../../components/flightplans/DrawArea";
import Rename from "../../common/dialogs/Rename";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import useUser from "../../common/hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewArea = () => {
  const [ renameDialogOpen, setRenameDialogOpen ] = React.useState<boolean>(false);
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[] | undefined>([]);

  const { userID, organizationID } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();

  const [ insertField ] = useMutation(gql`
    mutation InsertField($name: String!, $user_id: bigint!, $organization_id: bigint) {
      insert_fields(objects: {
        name: $name
        user_id: $user_id,
        organization_id: $organization_id,
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `, {
    refetchQueries: ['Fields', 'Field'],
  });

  const [ insertLatLng ] = useMutation(gql`
    mutation InsertLatLng($field_id: bigint!, $lat: numeric, $lng: numeric) {
      insert_lat_lngs(objects: {
        field_id: $field_id,
        lat: $lat,
        lng: $lng,
      }) {
        returning {
          id
        }
      }
    }
  `, {
    refetchQueries: ['Fields', 'Field'],
    onCompleted: () => {
      client.resetStore();
    }
  });

  const newField = React.useCallback(async (name: string) => {
    const res = await insertField({ variables: {
      name,
      user_id: userID,
      organization_id: organizationID,
    } });
    if (res.data) {
      const fieldID = res.data.insert_fields.returning[0].id;
      path?.forEach(async (latLng) => {
        await insertLatLng({ variables: {
          field_id: fieldID,
          lat: latLng.lat,
          lng: latLng.lng,
        } });
      });
      await client.resetStore();
    }
  }, [insertField, insertLatLng, path, organizationID, userID]);

  return (
    <React.Fragment>
      <DrawArea
        path={path}
        onPathChange={(p) => {
          setPath(p);
          setRenameDialogOpen(true);
        }}
      />

      <Rename
        open={renameDialogOpen}
        title={t("Area Name")}
        text={
          <React.Fragment>
            {t('Enter the name of the area.')}
          </React.Fragment>
        }
        onClose={async (name) => {
          setRenameDialogOpen(false);
          if (name) {
            await newField(name);
            navigate('/fly/areas')
          }
        }}
        confirmButtonText={t("Create")}
      />
    </React.Fragment>
  );
};

export default NewArea;
