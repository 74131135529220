export interface PersonalInfoData {
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phone: string;
  city: string;
  linkedin: string;
  isPublic: boolean;
}

export interface QualificationsData {
  id: string;
  pilotLicenses: string[];
  certificates: string[];
  flightHours: number;
  isPublic: boolean;
}

export interface EquipmentData {
  id: string;
  drones: Array<{
    model: string;
  }>;
  cameras: Array<{
    model: string;
  }>;
  software: Array<{
    name: string;
  }>;
  isPublic: boolean;
}

export interface ExperienceData {
  id: string;
  yearsOfExperience: string;
  missionCount: string;
  specializations: string[];
  isPublic: boolean;
}

export interface AvailabilityData {
  id: string;
  missionTypes: string[];
  operationalRange: number;
  isPublic: boolean;
}

export interface PilotProfileData {
  personalInfo: PersonalInfoData;
  qualifications: QualificationsData;
  equipment: EquipmentData;
  experience: ExperienceData;
  availability: AvailabilityData;
}

export class PilotProfile {
  private data: PilotProfileData;

  constructor() {
    // Initialize with default empty values
    this.data = {
      personalInfo: {
        id: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        phone: '',
        city: '',
        linkedin: '',
        isPublic: false
      },
      qualifications: {
        id: '',
        pilotLicenses: [],
        certificates: [],
        flightHours: 0,
        isPublic: false
      },
      equipment: {
        id: '',
        drones: [],
        cameras: [],
        software: [],
        isPublic: false
      },
      experience: {
        id: '',
        yearsOfExperience: '',
        missionCount: '',
        specializations: [],
        isPublic: false
      },
      availability: {
        id: '',
        missionTypes: [],
        operationalRange: 0,
        isPublic: false
      }
    };
  }

  // Getters
  getData(): PilotProfileData {
    return this.data;
  }

  getPersonalInfo(): PersonalInfoData {
    return this.data.personalInfo;
  }

  getQualifications(): QualificationsData {
    return this.data.qualifications;
  }

  getEquipment(): EquipmentData {
    return this.data.equipment;
  }

  getExperience(): ExperienceData {
    return this.data.experience;
  }

  getAvailability(): AvailabilityData {
    return this.data.availability;
  }

  // Setters
  setPersonalInfo(data: Partial<PersonalInfoData>) {
    this.data.personalInfo = { ...this.data.personalInfo, ...data, ...transformObject(data) };
  }

  setQualifications(data: Partial<QualificationsData>) {
    this.data.qualifications = { ...this.data.qualifications, ...data, ...transformObject(data) };
    this.data.qualifications.pilotLicenses = (data as any)?.pilot_licenses?.map((license: any) => license.license_name) || this.data.qualifications.pilotLicenses;
    this.data.qualifications.certificates = (data as any)?.certificates?.map((certificate: any) => certificate.cert_name) || this.data.qualifications.certificates;
  }

  setEquipment(data: Partial<EquipmentData>) {
    this.data.equipment = { ...this.data.equipment, ...data, ...transformObject(data) };
    this.data.equipment.drones = (data as any)?.drones_equipments?.map((drone: any) => ({ model: drone.drone_model })) || this.data.equipment.drones;
    this.data.equipment.cameras = (data as any)?.camera_equipments?.map((camera: any) => ({ model: camera.camera_model })) || this.data.equipment.cameras;
    this.data.equipment.software = (data as any)?.software_equipments?.map((software: any) => ({ name: software.name })) || this.data.equipment.software;
  }

  setExperience(data: Partial<ExperienceData>) {
    this.data.experience = { ...this.data.experience, ...data, ...transformObject(data) };
    this.data.experience.specializations = (data as any)?.specializations?.map((spec: any) => spec.name) || this.data.experience.specializations;
  }

  setAvailability(data: Partial<AvailabilityData>) {
    this.data.availability = { ...this.data.availability, ...data, ...transformObject(data) };
    this.data.availability.missionTypes = (data as any)?.mission_types?.map((type: any) => type.name) || this.data.availability.missionTypes;
  }

  // Validation methods
  validatePersonalInfo(): boolean {
    const { firstName, lastName, email } = this.data.personalInfo;
    return Boolean(firstName && lastName && email);
  }

  validateQualifications(): boolean {
    const { pilotLicenses, flightHours } = this.data.qualifications;
    return pilotLicenses.length > 0 && flightHours > 0;
  }

  validateEquipment(): boolean {
    return this.data.equipment.drones.length > 0;
  }

  validateExperience(): boolean {
    const { yearsOfExperience, missionCount } = this.data.experience;
    return Boolean(yearsOfExperience && missionCount);
  }

  validateAvailability(): boolean {
    return this.data.availability.missionTypes.length > 0;
  }

  // Utility methods
  isStepValid(step: keyof PilotProfileData): boolean {
    switch (step) {
      case 'personalInfo':
        return this.validatePersonalInfo();
      case 'qualifications':
        return this.validateQualifications();
      case 'equipment':
        return this.validateEquipment();
      case 'experience':
        return this.validateExperience();
      case 'availability':
        return this.validateAvailability();
      default:
        return false;
    }
  }

  isComplete(): boolean {
    return (
      this.validatePersonalInfo() &&
      this.validateQualifications() &&
      this.validateEquipment() &&
      this.validateExperience() &&
      this.validateAvailability()
    );
  }

  toJSON(): string {
    return JSON.stringify(this.data);
  }

  fromJSON(json: string): void {
    try {
      const parsed = JSON.parse(json) as PilotProfileData;
      this.data = parsed;
    } catch (error) {
      console.error('Error parsing pilot profile data:', error);
    }
  }

  fromGraphQL(data: any): void {
    if (!data) return;

    const transformedData = transformObject(data);

    // Update each section only if it exists in the input data
    if (transformedData.personalInfo) {
      this.setPersonalInfo(transformedData.personalInfo);
    }
    if (transformedData.qualifications) {
      this.setQualifications(transformedData.qualifications);
    }
    if (transformedData.equipment) {
      this.setEquipment(transformedData.equipment);
    }
    if (transformedData.experience) {
      this.setExperience(transformedData.experience);
    }
    if (transformedData.availability) {
      this.setAvailability(transformedData.availability);
    }
  }
}

const snakeToCamel = (str: string): string => 
  str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const transformObject = (obj: any): any => {
  if (!obj || typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) return obj.map(transformObject);
  
  return Object.entries(obj).reduce((acc: any, [key, value]) => {
    const camelKey = snakeToCamel(key);
    acc[camelKey] = transformObject(value);
    return acc;
  }, {});
};
