import React from 'react';
import questions from '../../data/exercises_a1_a3.json';
import { Container, Typography, List, ListItem, Button, Box } from '@mui/material';
import Loading from '../../common/components/Loading';
import { useTranslation } from 'react-i18next';

interface Question {
  question: string;
  answers: {
    text: string;
    correct: boolean;
  }[];
}

const Questions = () => {
  const [question, setQuestion] = React.useState<Question | undefined>();
  const [selectedAnswer, setSelectedAnswer] = React.useState<number | undefined>();
  const [showResult, setShowResult] = React.useState(false);

  const { t } = useTranslation();

  const loadRandomQuestion = () => {
    const randomQuestion = questions[Math.floor(Math.random() * questions.length)];
    setQuestion(randomQuestion);
    setSelectedAnswer(undefined);
    setShowResult(false);
  };

  React.useEffect(() => {
    loadRandomQuestion();
  }, []);

  const handleAnswerClick = (index: number) => {
    setSelectedAnswer(index);
    setShowResult(true);
  };

  if (!question) {
    return <Loading open />;
  }

  const correctAnswer = question.answers.find(answer => answer.correct);

  return (
    <Container maxWidth="sm" style={{
      padding: 16,
      paddingBottom: 32,
      overflowY: 'auto',
      height: '100%'
    }}>
      <Typography variant="h5" gutterBottom>{t(question.question)}</Typography>
      <List>
        {question.answers.map((answer, index) => (
          <ListItem key={index}>
            <Button 
              variant="contained" 
              fullWidth
              color='inherit'
              onClick={() => handleAnswerClick(index)}
              disabled={showResult}
            >
              {t(answer.text)}
            </Button>
          </ListItem>
        ))}
      </List>
      {showResult && selectedAnswer !== undefined && (
        <React.Fragment>
          <Typography variant="h6" color={question.answers[selectedAnswer].correct ? "green" : "red"}>
            {question.answers[selectedAnswer].correct ? t("Correct!") : t("Incorrect!")}
          </Typography>
          {!question.answers[selectedAnswer].correct && correctAnswer && (
            <Typography variant="body1">
              {t("The correct answer is:")} <strong>{t(correctAnswer.text)}</strong>
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" color="primary" onClick={loadRandomQuestion} fullWidth>
              {t("Next Question")}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Questions;
