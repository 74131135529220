export interface Document {
  name: string;
  path?: string;
  url?: string;
  requiredRoles?: string[];
};

export interface Learnable {
  uuid: string;
  name: string;
  updated_at: string;

  cover?: string;
  documents?: Document[];
};

export const learnables = [
  { uuid: '1', name: 'OPEN A1-A3', updated_at: '2021-01-01', documents: [
    { name: 'Theory Manual', path: '/pdf/Manuale di teoria A1-A3.pdf', requiredRoles: ['learn_open_a1_a3_manual'] },
    { name: 'Exercises', url: '/learn/questions', requiredRoles: ['learn_open_a1_a3_exercises'] },
  ], cover: '/imgs/learn/A1-A3.png' },
  { uuid: '2', name: 'OPEN A2', updated_at: '2021-01-02', documents: [
    { name: 'Theory Manual', path: undefined, requiredRoles: ['learn_open_a2_manual'] },
    { name: 'Exercises', path: undefined, requiredRoles: ['learn_open_a2_exercises'] },
  ], cover: '/imgs/learn/A2.png' },
];
