import { CameraSettings, Command, Movement } from "../common/grpc/common_pb";
import { Buffer } from 'buffer';

export interface RawFlightPlanMeta {
  version: number;
};

export interface RawFlightPlanCommand {
  type: number;
}

export class RawFlightPlan {
  meta: RawFlightPlanMeta;
  commands: RawFlightPlanCommand[];

  constructor(commands: RawFlightPlanCommand[] = [], version: number = 2) {
    this.meta = {
      version: version,
    };
    this.commands = commands;
  }

  static fromData(data: string): RawFlightPlan {
    // convert data from postgresql bytea format to base64 encoded JSON string
    const hexToBase64 = (hexInput: string): string => 
      (hexInput.replace('\\x', '').match(/\w{2}/g) ?? [])
      .map((a: string) => String.fromCharCode(parseInt(a, 16)))
      .join('');

    const b64 = hexToBase64(data);

    try {
      // decode data from base64 encoded JSON string
      const b = Buffer.from(b64, 'base64');
      const json = b.toString('utf8');
      const obj = JSON.parse(json);

      return new RawFlightPlan(obj.commands);
    } catch {
      const obj = JSON.parse(b64);

      const commands = obj.commands;
      if (commands) {
        return new RawFlightPlan(commands);
      }
      return new RawFlightPlan(obj, 1);
    }
  }

  // Convert to binary data.
  async toData(): Promise<string> {
    const data = {
      meta: this.meta,
      commands: this.commands,
    };
    return JSON.stringify(data);
  }

  path(): google.maps.LatLngLiteral[] {
    return this.commands
      .filter((command) => command.type === Command.CommandCode.MOVE_GPS)
      .map((command: any) => {
        if (!command.position) return { lat: command.latitude, lng: command.longitude };
        return {
          lat: command.position.latitude,
          lng: command.position.longitude,
        };
      });
  }
};

const getElevationForLocations = async (
  locations: google.maps.LatLngLiteral[],
): Promise<google.maps.ElevationResult[][]> => {
  const segments = locations.slice(0, -1).map((_, index) => [locations[index], locations[index + 1]]);
  const elevations = await Promise.all(segments.map(([start, end]) => getElevationForSegment(start, end)));
  return elevations;
}

const getElevationForSegment = async (
  start: google.maps.LatLngLiteral,
  end: google.maps.LatLngLiteral,
): Promise<google.maps.ElevationResult[]> => {
  const locations = [start, end];
  const length = google.maps.geometry.spherical.computeLength(locations);
  const distanceBetweenPoints = 20;

  const elevator = new google.maps.ElevationService();
  const res = await elevator.getElevationAlongPath({
    path: locations,
    samples: Math.max(Math.ceil(length / distanceBetweenPoints), 2),
  });
  return res.results;
}

const minElevation = (elevations: google.maps.ElevationResult[]): number => {
  return Math.min(...elevations.map((elevation) => elevation.elevation));
}

interface ElevationOptions {
  elevations: google.maps.ElevationResult[];
  minElevation: number;
  altitude: number;
  speed: number;
};

const elevationsToCommands = (
  options: ElevationOptions,
): RawFlightPlanCommand[] => {
  const commands = [];
  for (let i = 0; i < options.elevations.length; i++) {
    const elevation = options.elevations[i];
    if (!elevation.location) continue;
    commands.push({
      type: Command.CommandCode.MOVE_GPS,
      speed: newMovement({absolute_speed: options.speed}),
      position: newCoordinate(elevation.location.lat(), elevation.location.lng(), elevation.elevation - options.minElevation + options.altitude),
      auto_heading: true,
      constant_speed: i === options.elevations.length - 1 ? false : true
    });
  }
  return commands;
}

export interface GenerateFlightPlanOptions {
  altitude: number;
  speed: number;
  path: google.maps.LatLngLiteral[];
  pathRotated3D?: google.maps.LatLngLiteral[];
  rotated3D?: boolean;
  followTerrain?: boolean;
}

interface movementOptions {
  speed?: number;
  absolute_speed?: number;
}

const newMovement = (opts: movementOptions): any => {
  let obj: any = {
    direction: Movement.Direction.DIRECTION_AUTO,
  }
  if (opts.speed) {
    obj.speed = opts.speed;
  }
  if (opts.absolute_speed) {
    obj.absolute_speed = opts.absolute_speed;
  }

  return obj;
}

const newCoordinate = (lat: number, lng: number, alt: number, heading?: number): any => {
  return {
    latitude: lat,
    longitude: lng,
    altitude: alt,
    heading: heading,
  }
}

const cameraInitialization = (): any[] => {
  return [
    {
      type: Command.CommandCode.TILT_CAMERA,
      yaw: 0,
      pitch: -90,
      roll: 0,
      speed: newMovement({speed: Movement.Speed.VERY_FAST}),
    },
    {
      type: Command.CommandCode.HOVER,
      duration: 3,
    },
    {
      type: Command.CommandCode.TAKE_PHOTO,
      interval: 2,
      camera_settings: {
        photo_mode: CameraSettings.PhotoMode.PHOTO_MODE_INTERVAL,
      },
    },
  ];
}

export const generateFlightPlan = async (options: GenerateFlightPlanOptions): Promise<RawFlightPlan> => {
  let elevations: google.maps.ElevationResult[][] = [];
  if (options.rotated3D && options.pathRotated3D) {
    const totalPath = [...options.path, ...options.pathRotated3D];
    elevations = await getElevationForLocations(totalPath);
  } else {
    elevations = await getElevationForLocations(options.path);
  }
  const minElev = minElevation(elevations.flat());

  const commands: any[] = [];

  commands.push({
    type: Command.CommandCode.TAKEOFF,
    altitude: options.altitude,
    speed: newMovement({speed: Movement.Speed.MAX_SPEED}),
  });

  if (!options.followTerrain) {
    let commandsToAdd = options.path.map((point, index) => {
      return {
        type: Command.CommandCode.MOVE_GPS,
        speed: newMovement(index === 0 ? {speed: Movement.Speed.MAX_SPEED} : {absolute_speed: options.speed}),
        position: newCoordinate(point.lat, point.lng, options.altitude),
        auto_heading: true,
        constant_speed: index === options.path.length - 1 ? false : true,
      };
    });

    // Add camera initialization commands in position 1
    commandsToAdd.splice(1, 0, ...cameraInitialization());
    commands.push(...commandsToAdd);
  } else {
    if (options.rotated3D && options.pathRotated3D) {
      elevations = await getElevationForLocations(options.path);
    }
    let commandsToAdd = elevations.map((elevation) => {
      return elevationsToCommands({
        elevations: elevation,
        minElevation: minElev,
        altitude: options.altitude,
        speed: options.speed,
      });
    }).flat();

    // Add camera initialization commands in position 1
    commandsToAdd.splice(1, 0, ...cameraInitialization());
    commands.push(...commandsToAdd);
  }

  commands.push(...await add3DPath(options, minElev));

  commands.push({
    type: Command.CommandCode.STOP_CAMERA,
  });
  commands.push({
    type: Command.CommandCode.RETURN_HOME,
    altitude: Math.max(options.altitude, 20),
  });

  return new RawFlightPlan(commands);
};

const add3DPath = async (options: GenerateFlightPlanOptions, minElev: number): Promise<RawFlightPlanCommand[]> => {
  if (!options.rotated3D || !options.pathRotated3D) return [];

  const commands: any[] = [];

  commands.push({
    type: Command.CommandCode.TILT_CAMERA,
    yaw: 0,
    pitch: -45,
    roll: 0,
    speed: newMovement({speed: Movement.Speed.VERY_FAST}),
  });
  commands.push({
    type: Command.CommandCode.HOVER,
    duration: 3,
  });

  if (!options.followTerrain) {
    commands.push(
      ...options.pathRotated3D.map((point, index) => {
        return {
          type: Command.CommandCode.MOVE_GPS,
          speed: newMovement({absolute_speed: options.speed}),
          position: newCoordinate(point.lat, point.lng, options.altitude),
          auto_heading: true,
          constant_speed: index === options.pathRotated3D!.length - 1 ? false : true,
        };
      })
    );
  } else {
    const elevations = await getElevationForLocations(options.pathRotated3D);
    commands.push(
      ...elevations.map((elevation) => {
        return elevationsToCommands({
          elevations: elevation,
          minElevation: minElev,
          altitude: options.altitude,
          speed: options.speed,
        });
      }).flat()
    );
  }

  return commands;
};
