import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../common/hooks/useUser";
import Loading from "../../common/components/Loading";
import { useTranslation } from "react-i18next";

interface DroneModel {
  id: number;
  name: string;
}

const NewDrone = () => {
  const [ pageLoading, setPageLoading ] = React.useState(false);
  const [ droneModels, setDroneModels ] = React.useState<DroneModel[]>([]);
  const [ selectedDroneModelInput, setSelectedDroneModelInput ] = React.useState<string>('');
  const [ selectedDroneModel, setSelectedDroneModel ] = React.useState<DroneModel | null>(null);

  const navigate = useNavigate();
  const { userID, organizationID } = useUser();
  const { t } = useTranslation();

  const client = useApolloClient();

  const { loading, error, data } = useQuery(gql(`
    query DroneModels {
      drone_models {
        id
        name
      }
    }
  `));

  const [ insertDrone ] = useMutation(gql(`
    mutation InsertDrone($name: String!, $user_id: bigint!, $organization_id: bigint, $model_id: bigint) {
      insert_drones(objects: {
        name: $name
        user_id: $user_id,
        organization_id: $organization_id,
        drone_model_id: $model_id
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `), {
    refetchQueries: ['Fleet'],
    onCompleted: async () => {
      await client.resetStore();
    }
  });

  const goBack = React.useCallback(() => {
    window.history.back();
  }, []);

  const newDrone = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name') as string;
    
    if (!name) {
      return;
    }

    setPageLoading(true);

    const res = await insertDrone({ variables: {
      name,
      user_id: userID,
      organization_id: organizationID,
      model_id: selectedDroneModel?.id
    } });
    if (res.data) {
      navigate('..');
    }
  }, [insertDrone, navigate, organizationID, userID, selectedDroneModel]);

  const handleSuggestionClick = React.useCallback((droneModel: DroneModel) => {
    setSelectedDroneModel(droneModel);
    setSelectedDroneModelInput(droneModel.name);
  }, []);

  const onInputChange = React.useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setSelectedDroneModelInput((event.target as HTMLInputElement).value);
  }, []);

  React.useEffect(() => {
    if (!data) return;
    setDroneModels(data.drone_models);
  }, [data]);

  React.useEffect(() => {
    const selectedModel = droneModels.find((model) => model.name === selectedDroneModelInput);
    if (selectedModel) {
      setSelectedDroneModel(selectedModel);
    }
  }, [selectedDroneModelInput, droneModels]);

  if (pageLoading || loading) {
    return <Loading open />;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}
      onSubmit={newDrone}
      component="form">
        <Typography variant="h4" gutterBottom>
          {t('New Drone')}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("Name")}
          type="text"
          fullWidth
          variant="outlined"
        />
        <Autocomplete
          fullWidth
          options={droneModels}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            const { key, ...other } = props as any;

            return (
              <Box
                key={option.id}
                component="li"
                {...other}
                onClick={() => handleSuggestionClick(option)}
              >
                {option.name}
              </Box>
            );
          }}
          inputValue={selectedDroneModelInput}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              id="droneModel"
              name="droneModel"
              label={t("Drone Model")}
              type="text"
              fullWidth
              variant="standard"
              value={selectedDroneModelInput}
              onChange={(e) => {
                setSelectedDroneModelInput(e.target.value);
              }}
              onInput={onInputChange}
            />
          )}
          />
        <Stack spacing={2} direction="row" sx={{mt: 3}}>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            onClick={goBack}
          >
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('Create')}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}

export default NewDrone;
