import { Dialog, DialogContent, DialogContentText, DialogTitle, TextField, List, Paper, Portal, ListItemButton } from "@mui/material";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import React from "react";
import { useTranslation } from "react-i18next";

export interface SearchLocationDialogProps {
  open: boolean;
  onClose: (location?: google.maps.LatLngLiteral) => void;
}

const SearchLocationDialog = (props: SearchLocationDialogProps) => {
  const { open, onClose } = props;

  const [ inputValue, setInputValue ] = React.useState<string>('');
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const map = useMap();
  const places = useMapsLibrary('places');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    React.useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    React.useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    React.useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = React.useState<
    Array<{
      description: string;
      place_id: string;
    }>
  >([]);

  React.useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  React.useEffect(() => {
    predictionResults.forEach((prediction) => {
      console.log(prediction);
    });
  }, [predictionResults]);

  const fetchPredictions = React.useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      console.log('fetchPredictions', inputValue);
      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions.map((prediction) => ({
        description: prediction.description,
        place_id: prediction.place_id
      })));
    },
    [autocompleteService, sessionToken]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    fetchPredictions(value).then(() => {
      setAnchorEl(event.target);
    });
  };

  const handleSuggestionClick = React.useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['geometry', 'name', 'formatted_address'],
        sessionToken
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? '');
        setSessionToken(new places.AutocompleteSessionToken());

        onClose(placeDetails?.geometry?.location?.toJSON());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
      setAnchorEl(null);
    },
    [places, placesService, sessionToken]
  );

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>{t('Search Location')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Insert here your location where to center the map to.')}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="location"
            name="location"
            label={t("Location")}
            type="text"
            fullWidth
            variant="standard"
            value={inputValue}
            onChange={handleInputChange}
          />
          {anchorEl && Boolean(anchorEl) && predictionResults.length > 0 && (
            <Portal>
              <Paper
                style={{
                  position: 'absolute',
                  zIndex: 1500,
                  left: anchorEl.getBoundingClientRect().left,
                  top: anchorEl.getBoundingClientRect().bottom,
                  width: anchorEl.getBoundingClientRect().width
                }}
              >
                <List>
                  {predictionResults.map((option) => (
                    <ListItemButton
                      key={option.place_id}
                      onClick={() => handleSuggestionClick(option.place_id)}
                    >
                      {option.description}
                    </ListItemButton>
                  ))}
                </List>
              </Paper>
            </Portal>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SearchLocationDialog;
