import { Fab, Stack } from "@mui/material";
import React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useParams } from "react-router-dom";
import { OrganizationContext, OrganizationContextManager } from "../common/contexts/organization";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import useUser from "../common/hooks/useUser";
import AcquireFeature from "../common/pages/AcquireFeature";

export const appendCommonArgs = (url: string, organizationCtx: OrganizationContextManager | null) => {
  const separator = url.includes('?') ? '&' : '?';

  url += separator + "embed=true";
  if (organizationCtx?.organizationID) {
    url += "&organization=" + organizationCtx?.organizationID;
  }
  return url;
};

export interface FrameProps {
  url?: string;
  dense?: boolean;
  pinned?: boolean;
  style?: React.CSSProperties;
  hideButtons?: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  requiredRoles?: string[];
  featureName?: string;
}

const Frame = (props: FrameProps) => {
  const { path } = useParams();
  const { hasRole } = useUser();

  const organizationCtx = React.useContext(OrganizationContext);

  let fullURL = props.url ?? 'https://www.dromt.it';
  if (path) {
    fullURL += '/'+path;
  }

  fullURL = appendCommonArgs(fullURL, organizationCtx);

  if (props.requiredRoles && props.requiredRoles.length > 0) {
    for (const role of props.requiredRoles) {
      if (!hasRole(role)) {
        return (
          <AcquireFeature name={props.featureName} minRole={role} />
        );
      }
    }
  }

  if (props.collapsible && props.collapsed) {
    return (<React.Fragment>
      <div style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 10000,
      }}>
        <Fab onClick={() => props.setCollapsed && props.setCollapsed(!props.collapsed)}>
          <OpenInFullIcon />
        </Fab>
      </div>
    </React.Fragment>);
  }

  return <React.Fragment>
    <div style={{
      position: 'relative',
      height: '100%',
      width: '100%',
      ...props.style,
    }}>
      { !props.hideButtons && <React.Fragment>
        <Stack direction="row" spacing={1} style={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}>
          { props.collapsible && !props.collapsed && <Fab size="small" onClick={() => props.setCollapsed && props.setCollapsed(!props.collapsed)}>
            <CloseFullscreenIcon />
          </Fab> }
          { props.collapsible && props.collapsed && <Fab size="small" onClick={() => props.setCollapsed && props.setCollapsed(!props.collapsed)}>
            <OpenInFullIcon />
          </Fab> }
          {/* { props.pinned ?
          <Fab size="small">
            <PushPinIcon />
          </Fab>
          :
          <Fab size="small">
            <PushPinOutlinedIcon />
          </Fab>
          } */}
          <Fab size="small" onClick={() => {
            window.open(fullURL, '_blank');
          }}>
            <OpenInNewIcon />
          </Fab>
        </Stack>
      </React.Fragment> }
      <iframe
        src={ fullURL }
        style={{
          width: '100%',
          height: 'calc(100%)',
          border: 'none',
        }}
        loading="lazy"
        allow="geolocation; microphone; camera; clipboard-write"
      />
    </div>
  </React.Fragment>;
};

export default Frame;
