import React from 'react';
import { Box, TextField, FormControlLabel, Switch, Chip, Autocomplete, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QualificationsData } from '../../../classes/PilotProfile';
import { gql, useMutation } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';

interface QualificationsProps {
  data: QualificationsData;
}

const QualificationsSection: React.FC<QualificationsProps> = ({ data }) => {
  const [newData, setNewData] = React.useState(data);
  const { userID } = useUser();
  const { t } = useTranslation();

  const [qualificationsMutationUpdate] = useMutation(gql`
    mutation UpdateQualifications(
      $id: bigint!,
      $flight_hours: bigint,
      $is_public: Boolean,
    ) {
      update_qualifications_by_pk(pk_columns: {id: $id}, _set: {
        flight_hours: $flight_hours,
        is_public: $is_public,
      }) {
        id
      }
    }
  `);

  const [qualificationsMutationInsert] = useMutation(gql`
    mutation InsertQualifications(
      $user_id: bigint!,
      $flight_hours: bigint,
      $is_public: Boolean,
      $pilot_licenses: pilot_licenses_arr_rel_insert_input,
      $certificates: certificates_arr_rel_insert_input
    ) {
      insert_qualifications(objects: {
        user_id: $user_id,
        flight_hours: $flight_hours,
        is_public: $is_public,
        pilot_licenses: $pilot_licenses,
        certificates: $certificates
      }) {
        affected_rows
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const [pilotLicensesRemoveMutation] = useMutation(gql`
    mutation RemovePilotLicense($qualification_id: bigint!) {
      delete_pilot_licenses(where: {qualifications_id: {_eq: $qualification_id}}) {
        affected_rows
      }
    }
  `);

  const [certificatesRemoveMutation] = useMutation(gql`
    mutation RemoveCertificate($qualification_id: bigint!) {
      delete_certificates(where: {qualifications_id: {_eq: $qualification_id}}) {
        affected_rows
      }
    }
  `);

  const [pilotLicensesAddMutation] = useMutation(gql`
    mutation AddPilotLicense($qualification_id: bigint!, $license_name: String!) {
      insert_pilot_licenses(objects: {qualifications_id: $qualification_id, license_name: $license_name}) {
        affected_rows
      }
    }
  `);

  const [certificatesAddMutation] = useMutation(gql`
    mutation AddCertificate($qualification_id: bigint!, $cert_name: String!) {
      insert_certificates(objects: {qualifications_id: $qualification_id, cert_name: $cert_name}) {
        affected_rows
      }
    }
  `);

  const handleChange = (field: keyof QualificationsData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = field === 'isPublic' ? event.target.checked : 
                 field === 'flightHours' ? Number(event.target.value) : 
                 event.target.value;
    setNewData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleArrayChange = (field: 'pilotLicenses' | 'certificates') => (
    _event: React.SyntheticEvent,
    newValue: string[]
  ) => {
    setNewData(prevState => ({ ...prevState, [field]: newValue }));
  };

  const handleSave = async () => {
    const variables = {
      flight_hours: newData.flightHours,
      is_public: newData.isPublic,
      pilot_licenses: { data: newData.pilotLicenses.map(license => ({ license_name: license })) },
      certificates: { data: newData.certificates.map(certificate => ({ cert_name: certificate })) }
    };

    if (!data.id) {
      await qualificationsMutationInsert({
        variables: {
          user_id: userID,
          ...variables
        }
      });
    } else {
      await qualificationsMutationUpdate({
        variables: {
          id: data.id,
          ...variables
        }
      });

      // if liceses are different
      if (data.pilotLicenses.sort().join(',') !== newData.pilotLicenses.sort().join(',')) {
        console.log(data.id);
        await pilotLicensesRemoveMutation({
          variables: {
            qualification_id: data.id
          }
        });
        await Promise.all(
          newData.pilotLicenses.map(async (license) => {
            await pilotLicensesAddMutation({
              variables: {
                qualification_id: data.id,
                license_name: license
              }
            });
          })
        );
      }

      // if certificates are different
      if (data.certificates.sort().join(',') !== newData.certificates.sort().join(',')) {
        console.log(data.id);
        await certificatesRemoveMutation({
          variables: {
            qualification_id: data.id
          }
        });
        await Promise.all(
          newData.certificates.map(async (certificate) => {
            await certificatesAddMutation({
              variables: {
                qualification_id: data.id,
                cert_name: certificate
              }
            });
          })
        );
      }

      // refresh the graphql cache
      window.location.reload();
    }
  };

  // Common license types
  const commonLicenses = [
    'Open A1-A3',
    'Specific',
    'Night',
    'Extended Visual Line of Sight',
    'Beyond Visual Line of Sight'
  ];

  // Common certificate types
  const commonCertificates = [
    'FPV',
    'Night',
    'Thermal Inspections',
    'Photogrammetry',
    'Search and Rescue',
    'Agricultural',
    'Industrial Inspection'
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Autocomplete
        multiple
        id="pilotLicenses"
        options={commonLicenses}
        value={newData.pilotLicenses}
        onChange={handleArrayChange('pilotLicenses')}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.pilotLicenses')}
            placeholder={t('pilot.placeholders.addLicense')}
            required
          />
        )}
      />

      <Autocomplete
        multiple
        id="certificates"
        options={commonCertificates}
        value={newData.certificates}
        onChange={handleArrayChange('certificates')}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.certificates')}
            placeholder={t('pilot.placeholders.addCertificate')}
          />
        )}
      />

      <TextField
        label={t('pilot.fields.flightHours')}
        value={newData.flightHours}
        onChange={handleChange('flightHours')}
        type="number"
        inputProps={{ min: 0 }}
        required
        fullWidth
      />

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default QualificationsSection;
