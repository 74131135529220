import React from 'react';
import { 
  Box, 
  TextField, 
  FormControlLabel, 
  Switch, 
  Chip,
  Autocomplete,
  Slider,
  Typography,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailabilityData } from '../../../classes/PilotProfile';
import { gql, useMutation } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';

interface AvailabilityProps {
  data: AvailabilityData;
}

const Availability: React.FC<AvailabilityProps> = ({ data }) => {
  const [newData, setNewData] = React.useState(data);
  const { userID } = useUser();
  const { t } = useTranslation();

  const [availabilityMutationUpdate] = useMutation(gql`
    mutation UpdateAvailability(
      $id: bigint!,
      $is_public: Boolean,
      $operational_range: bigint
    ) {
      update_availabilities_by_pk(pk_columns: {id: $id}, _set: {
        is_public: $is_public,
        operational_range: $operational_range
      }) {
        id
      }
    }
  `);

  const [availabilityMutationInsert] = useMutation(gql`
    mutation InsertAvailability(
      $user_id: bigint!,
      $is_public: Boolean,
      $operational_range: bigint,
      $mission_types: mission_types_arr_rel_insert_input
    ) {
      insert_availabilities(objects: {
        user_id: $user_id,
        is_public: $is_public,
        operational_range: $operational_range,
        mission_types: $mission_types
      }) {
        returning {
          id
        }
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const [missionTypesRemoveMutation] = useMutation(gql`
    mutation RemoveMissionTypes($availability_id: bigint!) {
      delete_mission_types(where: {availability_id: {_eq: $availability_id}}) {
        affected_rows
      }
    }
  `);

  const [missionTypesAddMutation] = useMutation(gql`
    mutation AddMissionType($availability_id: bigint!, $name: String!) {
      insert_mission_types(objects: {availability_id: $availability_id, name: $name}) {
        affected_rows
      }
    }
  `);

  const handleChange = (field: keyof AvailabilityData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = field === 'isPublic' ? event.target.checked : event.target.value;
    setNewData({ ...newData, [field]: value });
  };

  const handleMissionTypesChange = (_event: React.SyntheticEvent, newValue: string[]) => {
    setNewData({ ...newData, missionTypes: newValue });
  };

  const handleRangeChange = (_event: Event, newValue: number | number[]) => {
    setNewData({ ...newData, operationalRange: newValue as number });
  };

  const handleSave = async () => {
    if (!data.id) {
      await availabilityMutationInsert({
        variables: {
          user_id: userID,
          is_public: newData.isPublic,
          operational_range: newData.operationalRange,
          mission_types: {
            data: newData.missionTypes.map(type => ({ name: type }))
          }
        }
      });
    } else {
      await availabilityMutationUpdate({
        variables: {
          id: data.id,
          is_public: newData.isPublic,
          operational_range: newData.operationalRange
        }
      });

      // Update mission types if changed
      if (data.missionTypes.sort().join(',') !== newData.missionTypes.sort().join(',')) {
        await missionTypesRemoveMutation({
          variables: {
            availability_id: data.id
          }
        });
        await Promise.all(
          newData.missionTypes.map(async (type) => {
            await missionTypesAddMutation({
              variables: {
                availability_id: data.id,
                name: type
              }
            });
          })
        );
      }

      // refresh the graphql cache
      window.location.reload();
    }
  };

  // Common mission types
  const commonMissionTypes = [
    'Aerial photogrammetry',
    'Surveillance',
    'Emergency response',
    'Power lines',
    'Photovoltaic',
    'Agriculture',
    'Construction',
    'Real estate',
    'Film and media',
    'Search and rescue',
    'Industrial inspection',
    'Thermal inspection',
    'Environmental monitoring'
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Autocomplete
        multiple
        options={commonMissionTypes}
        value={newData.missionTypes}
        onChange={handleMissionTypesChange}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.missionTypes')}
            placeholder={t('pilot.placeholders.addMissionType')}
            required
          />
        )}
      />

      <Box>
        <Typography gutterBottom>
          {t('pilot.fields.operationalRange')} (km)
        </Typography>
        <Slider
          value={newData.operationalRange}
          onChange={handleRangeChange}
          min={0}
          max={500}
          step={10}
          marks={[
            { value: 0, label: '0' },
            { value: 100, label: '100' },
            { value: 250, label: '250' },
            { value: 500, label: '500' }
          ]}
          valueLabelDisplay="auto"
        />
      </Box>

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSave}
        sx={{ mt: 2 }}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default Availability;
