import React from 'react';
import { Box, useTheme, useMediaQuery, Button, Toolbar, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PilotStepper from '../../components/pilot/PilotStepper';
import { PilotProfile } from '../../classes/PilotProfile';
import PersonalInfo from '../../components/pilot/sections/PersonalInfo';
import Qualifications from '../../components/pilot/sections/Qualifications';
import Equipment from '../../components/pilot/sections/Equipment';
import Experience from '../../components/pilot/sections/Experience';
import Availability from '../../components/pilot/sections/Availability';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { and, whereNotDeleted } from '../../common/utils/graphql';
import useUser from '../../common/hooks/useUser';
import Loading from '../../common/components/Loading';

const PilotLayout: React.FC = () => {
  const [profile, setProfile] = React.useState<PilotProfile>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { userID } = useUser();

  const { loading, error, data } = useQuery(gql(`
    query UserProfiles {
      personal_infos(where: `+and(whereNotDeleted(), `{user_id: {_eq: ${userID}}}`)+`) {
        id
        birth_date
        city
        first_name
        is_public
        last_name
        linkedin
        phone
      }
      qualifications(where: `+and(whereNotDeleted(), `{user_id: {_eq: ${userID}}}`)+`) {
        id
        flight_hours
        is_public
        certificates {
          cert_name
        }
        pilot_licenses {
          license_name
        }
      }
      equipment(where: `+and(whereNotDeleted(), `{user_id: {_eq: ${userID}}}`)+`) {
        id
        camera_equipments {
          camera_model
        }
        drone_equipments {
          drone_model
        }
        is_public
        software_equipments {
          name
        }
      }
      experiences(where: `+and(whereNotDeleted(), `{user_id: {_eq: ${userID}}}`)+`) {
        id
        is_public
        mission_count
        specializations {
          name
        }
        years_of_experience
      }
      availabilities(where: `+and(whereNotDeleted(), `{user_id: {_eq: ${userID}}}`)+`) {
        id
        is_public
        operational_range
        mission_types {
          name
        }
      }
    }
  `));

  // Get current step from URL
  const currentStep = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const stepParam = searchParams.get('step');
    return stepParam ? parseInt(stepParam, 10) : 0;
  }, [location.search]);

  // Handle step changes
  const handleStepChange = (step: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('step', step.toString());
    navigate(`/pilot/profile?${searchParams.toString()}`, { replace: true });
  };

  // Handle profile changes
  const handleProfileChange = (section: string, data: any) => {
    setProfile(prev => {
      const updated = new PilotProfile();
      updated.fromJSON(JSON.stringify(prev));
      switch(section) {
        case 'personalInfo':
          updated.setPersonalInfo(data);
          break;
        case 'qualifications':
          updated.setQualifications(data);
          break;
        case 'equipment':
          updated.setEquipment(data);
          break;
        case 'experience':
          updated.setExperience(data);
          break;
        case 'availability':
          updated.setAvailability(data);
          break;
      }
      return updated;
    });
  };

  React.useEffect(() => {
    if (!data) return;

    const profile = new PilotProfile();
    profile.setPersonalInfo(data.personal_infos[0]);
    profile.setQualifications(data.qualifications[0]);
    profile.setEquipment(data.equipment[0]);
    profile.setExperience(data.experiences[0]);
    profile.setAvailability(data.availabilities[0]);
    setProfile(profile);
  }, [data]);

  if (loading || !profile) return <Loading open />;

  return (
    <Box sx={{ 
      height: '100%', 
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Toolbar 
        variant="dense" 
        sx={{ 
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          px: 2,
          minHeight: 56
        }}
      >
        <Typography variant="h6" sx={{ flex: 1 }}>
          {t('Profile')}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<PictureAsPdfIcon />}
          onClick={() => navigate('/pilot/profile/view?pdf=true')}
          size="small"
          sx={{ mr: 2 }}
        >
          {t('pilot.actions.viewPDF')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => navigate('/pilot/profile/view')}
          size="small"
        >
          {t('pilot.actions.viewProfile')}
        </Button>
      </Toolbar>

      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        p: 2
      }}>
        <Box sx={{ 
          display: 'flex', 
          gap: 2
        }}>
          <Box sx={{ width: { xs: isMobile ? '100%' : '33.33%' } }}>
            <PilotStepper
              activeStep={currentStep}
              onStepChange={handleStepChange}
              showContent={isMobile}
              profile={profile}
              onProfileChange={handleProfileChange}
            />
          </Box>
          {!isMobile && (
            <Box sx={{ flex: 1 }}>
              {React.cloneElement(
                [
                  <PersonalInfo 
                    data={profile.getPersonalInfo()}
                  />,
                  <Qualifications 
                    data={profile.getQualifications()}
                  />,
                  <Equipment 
                    data={profile.getEquipment()}
                  />,
                  <Experience 
                    data={profile.getExperience()}
                  />,
                  <Availability 
                    data={profile.getAvailability()}
                  />
                ][currentStep]
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PilotLayout;
