import React from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const TrackPageView = () => {
  const location = useLocation();

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "pageview",
        page: location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  return null;
};

export default TrackPageView;
