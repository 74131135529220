import { gql, useQuery } from "@apollo/client";
import React from "react";
import { and, whereNotDeleted } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useScreenSize } from "../common/hooks/useScreenSize";
import { agri, gray } from "../theme";
import { Application, groupByName } from "../classes/downloadable";
import { dateFormat } from "../common/utils/text";
import useApi from "../common/hooks/useApi";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from "react-i18next";

interface DownloadPaginationProps {
  currentPage: number;
  pages: number;
  onPageChange: (page: number) => void;
}

const DownloadPagination = (props: DownloadPaginationProps) => {
  return (
    <Box style={{
      padding: '16px',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Pagination
        count={props.pages}
        page={props.currentPage}
        onChange={(e, page) => {
          props.onPageChange(page);
        }}
        color="primary" />
    </Box>
  );
};

interface ApplicationCardProps {
  application: Application;
};

const ApplicationCard = (props: ApplicationCardProps) => {
  const { get } = useApi();
  const { t } = useTranslation();
  const { verticalSmall } = useScreenSize();

  const download = async (uuid: string, name: string) => {
    const response: any = await get(`/downloads/${uuid}`);
    const url = response.url;

    if (!url) return;

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener');

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <Card style={{
      height: '100%',
      maxHeight: '450px',
      backgroundColor: gray,
      borderRadius: '16px',
      textAlign: 'center',
    }}>
      <Stack style={{
        height: '100%',
      }}>
        <CardContent style={{
          height: 'calc(100% - 60px)',
          position: 'relative',
        }}>
          <Stack spacing={2} style={{
            height: '100%',
          }}>
            <Typography variant="h5" component="div">
              { props.application.name }
            </Typography>
            <Typography variant="h6">
              {t('Latest Version')}: { props.application.version }
            </Typography>
            <Typography variant="body1">
              {t('Released')}: { dateFormat(props.application.created_at) }
            </Typography>
            <Divider style={{
              borderColor: 'rgba(255, 255, 255, 0.2)',
            }} />
            { !verticalSmall && <React.Fragment>
              <Typography variant="h6">
                {t('Previous Versions')}
              </Typography>
              <div style={{
                overflowY: 'auto',
              }}>
                <table style={{
                  width: '100%',
                }}>
                  <tbody>
                    { props.application.downloadables.slice(1).map((downloadable) => (
                      <tr key={downloadable.uuid}>
                        <td>
                          { downloadable.version }
                        </td>
                        <td>
                          { dateFormat(downloadable.created_at) }
                        </td>
                        <td>
                          <Button variant="text" onClick={async () => {
                            await download(downloadable.uuid, downloadable.name);
                          }}>
                            {t('Download')}
                          </Button>
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            </React.Fragment> }
          </Stack>
        </CardContent>
        <CardActions style={{
          backgroundColor: agri,
          height: '60px',
          padding: 0,
        }}>
          <React.Fragment>
            <Stack direction="row"
            spacing={4}
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}>
              <Stack direction="row" spacing={1}>
                <CloudDownloadIcon fontSize="large" />
                <Typography style={{
                  cursor: 'pointer',
                }} variant="h6" onClick={async () => {
                  await download(props.application.uuid, props.application.name);
                }}>
                  {t('Download')} { props.application.version }
                </Typography>
              </Stack>
            </Stack>
          </React.Fragment>
        </CardActions>
      </Stack>
    </Card>
  );
};

interface ApplicationPageProps {
  applications: Application[];
}

const ApplicationPage = (props: ApplicationPageProps) => {
  const { vertical, verticalSmall } = useScreenSize();

  return (
    <Grid container spacing={2} style={{
      height: 'calc(100% - 64px)',
      padding: '32px',
      paddingBottom: 0,
    }}>
      { props.applications.map((app) => (
        <Grid item xs={vertical ? 12 : 6} md={vertical ? 6 : 4} lg={vertical ? 4 : 4} xl={3} key={app.uuid}
        style={{
          paddingTop: verticalSmall ? '0' : '16px',
        }}>
          <ApplicationCard
            application={app}
          />
        </Grid>
      )) }
    </Grid>
  );
};

const Download = () => {
  const [ applications, setApplications ] = React.useState<Application[]>([]);
  const [ itemsPerPage, setItemsPerPage ] = React.useState(1);
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState<Application[]>([]);

  const { medium, large, extraLarge, vertical } = useScreenSize();

  const { loading, error, data } = useQuery(gql(`
    query Downloadables {
      downloadables(where: `+and(whereNotDeleted())+`, order_by: {created_at: desc}) {
        uuid
        name
        created_at
        version
      }
    }
  `));

  React.useEffect(() => {
    if (data) {
      setApplications(groupByName(data.downloadables));
    } else {
      setApplications([]);
    }
  }, [data]);

  React.useEffect(() => {
    if (extraLarge) {
      setItemsPerPage(4);
    } else if (large) {
      setItemsPerPage(vertical ? 3 : 3);
    } else if (medium) {
      setItemsPerPage(vertical ? 2 : 3);
    } else {
      setItemsPerPage(vertical ? 1 : 2);
    }
  }, [medium, large, extraLarge, vertical]);

  React.useEffect(() => {
    const start = (currentPageNumber - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setCurrentPage(applications.slice(start, end));
  }, [applications, itemsPerPage, currentPageNumber]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <Stack style={{
        height: '100%',
      }}>
        <ApplicationPage
          applications={currentPage} />
        <DownloadPagination
          onPageChange={(page) => setCurrentPageNumber(page)}
          currentPage={currentPageNumber}
          pages={Math.ceil(applications.length / itemsPerPage)} />
      </Stack>
    </React.Fragment>
  );
};

export default Download;
