import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { stringAvatar } from "../common/utils/avatar";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import Iubenda from "../common/components/Iubenda";
import LogoutIcon from '@mui/icons-material/Logout';

const AccountMenu = () => {
  const [ userOpen, setUserOpen ] = React.useState(false);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null);

  const { user, removeUser, signoutSilent, clearStaleState } = useAuth();

  const { t } = useTranslation();

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserOpen(true);
    setAnchorElUser(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserOpen(false);
    setAnchorElUser(null);
  };

  const handleEditProfile = () => {
    const a = document.createElement('a');
    a.href = 'https://identity.dromt.it/realms/Dromt/account/';
    a.target = '_blank';
    a.click();
    handleUserClose();
    a.remove();
  };

  const handleLogout = () => {
    removeUser();
    signoutSilent({
      id_token_hint: user?.id_token,
    });
    clearStaleState();

    // reload the page
    window.location.reload();
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-controls={userOpen ? 'menu-user' : undefined}
        aria-haspopup="true"
        aria-expanded={userOpen ? 'true' : undefined}
        onClick={handleUserMenu}
        color="inherit"
      >
        <Avatar
          {...stringAvatar(user?.profile.name ?? user?.profile.email ?? t('User'))} />
      </IconButton>
      <Menu
        id="menu-user"
        anchorEl={anchorElUser}
        open={userOpen}
        onClose={handleUserClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={handleEditProfile}>
          <ListItemIcon style={{ marginRight: 8 }}>
            <Avatar {...stringAvatar(user?.profile.name ?? user?.profile.email ?? t('User'))} />
          </ListItemIcon>
          <ListItemText>{user?.profile.name ?? user?.profile.email ?? t('User')}</ListItemText>
        </MenuItem>

        <Divider />

        <Iubenda menuItems />

        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Logout")}</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
