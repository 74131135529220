import React from 'react';
import { Box, Grid, Typography, Button, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { PilotProfile } from '../../classes/PilotProfile';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { gql, useQuery } from '@apollo/client';
import { and, whereNotDeleted } from '../../common/utils/graphql';
import Loading from '../../common/components/Loading';
import useUser from '../../common/hooks/useUser';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <Box sx={{ mb: 4 }}>
    <Typography 
      variant="h6" 
      sx={{ 
        mb: 2,
        color: 'primary.main',
        fontWeight: 'medium',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        fontSize: '1.1rem'
      }}
    >
      {title}
    </Typography>
    <Box sx={{ ml: 2 }}>{children}</Box>
  </Box>
);

interface FieldProps {
  label: string;
  value: string | number | string[] | undefined;
}

const Field: React.FC<FieldProps> = ({ label, value }) => (
  <Box sx={{ 
    mb: 2,
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: { xs: 'flex-start', sm: 'center' },
    gap: 1
  }}>
    <Typography 
      sx={{ 
        minWidth: '200px',
        color: 'text.secondary',
        fontWeight: 500,
        fontSize: '0.9rem'
      }}
    >
      {label}
    </Typography>
    {Array.isArray(value) ? (
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: 1 
      }}>
        {value.length > 0 ? value.map((item, index) => (
          <Typography
            key={index}
            sx={{
              bgcolor: 'action.hover',
              px: 1.5,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.9rem'
            }}
          >
            {item}
          </Typography>
        )) : (
          <Typography color="text.secondary">-</Typography>
        )}
      </Box>
    ) : (
      <Typography sx={{ fontSize: '0.9rem' }}>
        {value || '-'}
      </Typography>
    )}
  </Box>
);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
  },
  section: {
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    color: '#1976d2',
    textTransform: 'uppercase',
  },
  field: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: 200,
    fontSize: 12,
    color: '#666666',
  },
  value: {
    fontSize: 12,
  },
  listItem: {
    marginLeft: 20,
    marginBottom: 3,
    fontSize: 12,
  },
  equipmentItem: {
    marginLeft: 20,
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#f5f5f5',
  },
  equipmentTitle: {
    fontSize: 14,
    marginBottom: 5,
    color: '#333333',
  },
});

const PilotPDF: React.FC<{ profile: PilotProfile }> = ({ profile }) => {
  const { t } = useTranslation();
  const personalInfo = profile.getPersonalInfo();
  const qualifications = profile.getQualifications();
  const equipment = profile.getEquipment();
  const experience = profile.getExperience();
  const availability = profile.getAvailability();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>{t('pilot.sections.personalInfo')}</Text>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.firstName')}</Text>
            <Text style={styles.value}>{personalInfo.firstName}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.lastName')}</Text>
            <Text style={styles.value}>{personalInfo.lastName}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.birthDate')}</Text>
            <Text style={styles.value}>{personalInfo.birthDate}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.email')}</Text>
            <Text style={styles.value}>{personalInfo.email}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.phone')}</Text>
            <Text style={styles.value}>{personalInfo.phone}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.city')}</Text>
            <Text style={styles.value}>{personalInfo.city}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.linkedin')}</Text>
            <Text style={styles.value}>{personalInfo.linkedin}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>{t('pilot.sections.qualifications')}</Text>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.pilotLicenses')}</Text>
            <View>
              {qualifications.pilotLicenses.map((license, index) => (
                <Text key={index} style={styles.listItem}>• {license}</Text>
              ))}
            </View>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.certificates')}</Text>
            <View>
              {qualifications.certificates.map((cert, index) => (
                <Text key={index} style={styles.listItem}>• {cert}</Text>
              ))}
            </View>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.flightHours')}</Text>
            <Text style={styles.value}>{qualifications.flightHours}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>{t('pilot.sections.equipment')}</Text>
          <Text style={styles.equipmentTitle}>{t('pilot.fields.drones')}</Text>
          {equipment.drones.map((drone, index) => (
            <View key={index} style={styles.equipmentItem}>
              <View style={styles.field}>
                <Text style={styles.label}>{t('pilot.fields.droneModel')}</Text>
                <Text style={styles.value}>{drone.model}</Text>
              </View>
            </View>
          ))}

          <Text style={styles.equipmentTitle}>{t('pilot.fields.cameras')}</Text>
          {equipment.cameras.map((camera, index) => (
            <View key={index} style={styles.equipmentItem}>
              <View style={styles.field}>
                <Text style={styles.label}>{t('pilot.fields.cameraModel')}</Text>
                <Text style={styles.value}>{camera.model}</Text>
              </View>
            </View>
          ))}

          <Text style={styles.equipmentTitle}>{t('pilot.fields.software')}</Text>
          {equipment.software.map((sw, index) => (
            <View key={index} style={styles.equipmentItem}>
              <View style={styles.field}>
                <Text style={styles.label}>{t('pilot.fields.softwareName')}</Text>
                <Text style={styles.value}>{sw.name}</Text>
              </View>
            </View>
          ))}
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>{t('pilot.sections.experience')}</Text>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.yearsOfExperience')}</Text>
            <Text style={styles.value}>{`${experience.yearsOfExperience} ${t('pilot.fields.years')}`}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.missionCount')}</Text>
            <Text style={styles.value}>{`${experience.missionCount} ${t('pilot.fields.missions')}`}</Text>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.specializations')}</Text>
            <View>
              {experience.specializations.map((spec, index) => (
                <Text key={index} style={styles.listItem}>• {spec}</Text>
              ))}
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>{t('pilot.sections.availability')}</Text>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.missionTypes')}</Text>
            <View>
              {availability.missionTypes.map((type, index) => (
                <Text key={index} style={styles.listItem}>• {type}</Text>
              ))}
            </View>
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>{t('pilot.fields.operationalRange')}</Text>
            <Text style={styles.value}>{`${availability.operationalRange} km`}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PilotView: React.FC = () => {
  const [profile, setProfile] = React.useState<PilotProfile>();
  const [showPDF, setShowPDF] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userID } = useUser();

  const { loading, error, data } = useQuery(gql(`
    query UserProfile($user_id: bigint!) {
      personal_infos(where: `+and(whereNotDeleted(), `{user_id: {_eq: $user_id}}`)+`) {
        id
        birth_date
        city
        first_name
        is_public
        last_name
        linkedin
        phone
      }
      qualifications(where: `+and(whereNotDeleted(), `{user_id: {_eq: $user_id}}`)+`) {
        id
        flight_hours
        is_public
        certificates {
          cert_name
        }
        pilot_licenses {
          license_name
        }
      }
      equipment(where: `+and(whereNotDeleted(), `{user_id: {_eq: $user_id}}`)+`) {
        id
        camera_equipments {
          camera_model
        }
        drone_equipments {
          drone_model
        }
        is_public
        software_equipments {
          name
        }
      }
      experiences(where: `+and(whereNotDeleted(), `{user_id: {_eq: $user_id}}`)+`) {
        id
        is_public
        mission_count
        specializations {
          name
        }
        years_of_experience
      }
      availabilities(where: `+and(whereNotDeleted(), `{user_id: {_eq: $user_id}}`)+`) {
        id
        is_public
        operational_range
        mission_types {
          name
        }
      }
    }
  `), {
    variables: { user_id: userID },
  });

  React.useEffect(() => {
    if (data) {
      const newProfile = new PilotProfile();
      if (data.personal_infos?.[0]) {
        newProfile.setPersonalInfo(data.personal_infos[0]);
      }
      if (data.qualifications?.[0]) {
        newProfile.setQualifications(data.qualifications[0]);
      }
      if (data.equipment?.[0]) {
        newProfile.setEquipment(data.equipment[0]);
      }
      if (data.experiences?.[0]) {
        newProfile.setExperience(data.experiences[0]);
      }
      if (data.availabilities?.[0]) {
        newProfile.setAvailability(data.availabilities[0]);
      }
      setProfile(newProfile);
    }
  }, [data]);

  if (loading || !data) return <Loading open />;
  if (!profile) return <Typography>{t('pilot.errors.notFound')}</Typography>;

  const handleEditProfile = () => {
    navigate('/pilot/profile');
  };

  const handleTogglePDF = (show: boolean) => {
    if (show) {
      navigate('/pilot/profile/view?pdf=true', { replace: true });
    } else {
      navigate('/pilot/profile/view', { replace: true });
    }
    setShowPDF(show);
  };

  if (showPDF) {
    return (
      <Box sx={{ 
        height: '100%', 
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Toolbar 
          variant="dense" 
          sx={{ 
            bgcolor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            px: 2,
            minHeight: 56
          }}
        >
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => handleTogglePDF(false)}
            size="small"
            sx={{ mr: 2 }}
          >
            {t('common.back')}
          </Button>
          <Typography variant="h6" sx={{ flex: 1 }}>
            {t('pilot.actions.pdfView')}
          </Typography>
        </Toolbar>
        <Box sx={{ flex: 1 }}>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <PilotPDF profile={profile} />
          </PDFViewer>
        </Box>
      </Box>
    );
  }

  const personalInfo = profile.getPersonalInfo();
  const qualifications = profile.getQualifications();
  const equipment = profile.getEquipment();
  const experience = profile.getExperience();
  const availability = profile.getAvailability();

  return (
    <Box sx={{ 
      height: '100%', 
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Toolbar 
        variant="dense" 
        sx={{ 
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          px: 2,
          minHeight: 56
        }}
      >
        <Typography variant="h6" sx={{ flex: 1 }}>
          {t('Profile')}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={handleEditProfile}
          size="small"
          sx={{ mr: 2 }}
        >
          {t('pilot.actions.editProfile')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<PictureAsPdfIcon />}
          onClick={() => handleTogglePDF(true)}
          size="small"
        >
          {t('pilot.actions.viewPDF')}
        </Button>
      </Toolbar>
      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        p: 3
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section title={t('pilot.sections.personalInfo')}>
              <Field label={t('pilot.fields.firstName')} value={personalInfo.firstName} />
              <Field label={t('pilot.fields.lastName')} value={personalInfo.lastName} />
              <Field label={t('pilot.fields.birthDate')} value={personalInfo.birthDate} />
              <Field label={t('pilot.fields.email')} value={personalInfo.email} />
              <Field label={t('pilot.fields.phone')} value={personalInfo.phone} />
              <Field label={t('pilot.fields.city')} value={personalInfo.city} />
              <Field label={t('pilot.fields.linkedin')} value={personalInfo.linkedin} />
              <Field label={t('pilot.fields.publicProfile')} value={personalInfo.isPublic ? t('common.yes') : t('common.no')} />
            </Section>

            <Section title={t('pilot.sections.qualifications')}>
              <Field label={t('pilot.fields.pilotLicenses')} value={qualifications.pilotLicenses} />
              <Field label={t('pilot.fields.certificates')} value={qualifications.certificates} />
              <Field label={t('pilot.fields.flightHours')} value={qualifications.flightHours} />
            </Section>

            <Section title={t('pilot.sections.equipment')}>
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: 2,
                    color: 'text.secondary',
                    fontWeight: 500
                  }}
                >
                  {t('pilot.fields.drones')}
                </Typography>
                {equipment.drones.map((drone, index) => (
                  <Box key={index} sx={{ 
                    ml: 2, 
                    mb: 2,
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1
                  }}>
                    <Field label={t('pilot.fields.droneModel')} value={drone.model} />
                  </Box>
                ))}
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: 2,
                    color: 'text.secondary',
                    fontWeight: 500
                  }}
                >
                  {t('pilot.fields.cameras')}
                </Typography>
                {equipment.cameras.map((camera, index) => (
                  <Box key={index} sx={{ 
                    ml: 2, 
                    mb: 2,
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1
                  }}>
                    <Field label={t('pilot.fields.cameraModel')} value={camera.model} />
                  </Box>
                ))}
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: 2,
                    color: 'text.secondary',
                    fontWeight: 500
                  }}
                >
                  {t('pilot.fields.software')}
                </Typography>
                {equipment.software.map((sw, index) => (
                  <Box key={index} sx={{ 
                    ml: 2, 
                    mb: 2,
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1
                  }}>
                    <Field label={t('pilot.fields.softwareName')} value={sw.name} />
                  </Box>
                ))}
              </Box>

            </Section>

            <Section title={t('pilot.sections.experience')}>
              <Field 
                label={t('pilot.fields.yearsOfExperience')} 
                value={`${experience.yearsOfExperience} ${t('pilot.fields.years')}`} 
              />
              <Field 
                label={t('pilot.fields.missionCount')} 
                value={`${experience.missionCount} ${t('pilot.fields.missions')}`} 
              />
              <Field label={t('pilot.fields.specializations')} value={experience.specializations} />
            </Section>

            <Section title={t('pilot.sections.availability')}>
              <Field label={t('pilot.fields.missionTypes')} value={availability.missionTypes} />
              <Field 
                label={t('pilot.fields.operationalRange')} 
                value={`${availability.operationalRange} km`} 
              />
            </Section>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PilotView;
