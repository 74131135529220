import React from 'react';
import { 
  Box, 
  TextField, 
  FormControlLabel, 
  Switch, 
  Button, 
  Typography,
  Chip,
  Autocomplete
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EquipmentData } from '../../../classes/PilotProfile';
import { gql, useMutation } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';

interface EquipmentProps {
  data: EquipmentData;
}

const Equipment: React.FC<EquipmentProps> = ({ data }) => {
  const [newData, setNewData] = React.useState(data);
  const { userID } = useUser();
  const { t } = useTranslation();

  const [equipmentMutationUpdate] = useMutation(gql`
    mutation UpdateEquipment(
      $id: bigint!,
      $is_public: Boolean,
    ) {
      update_equipment_by_pk(pk_columns: {id: $id}, _set: {
        is_public: $is_public,
      }) {
        id
      }
    }
  `);

  const [equipmentMutationInsert] = useMutation(gql`
    mutation InsertEquipment(
      $user_id: bigint!,
      $is_public: Boolean,
      $camera_equipments: camera_equipments_arr_rel_insert_input,
      $software_equipments: software_equipments_arr_rel_insert_input
    ) {
      insert_equipment(objects: {
        user_id: $user_id,
        is_public: $is_public,
        camera_equipments: $camera_equipments,
        software_equipments: $software_equipments
      }) {
        returning {
          id
        }
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const [cameraEquipmentRemoveMutation] = useMutation(gql`
    mutation RemoveCameraEquipment($equipment_id: bigint!) {
      delete_camera_equipments(where: {equipment_id: {_eq: $equipment_id}}) {
        affected_rows
      }
    }
  `);

  const [softwareEquipmentRemoveMutation] = useMutation(gql`
    mutation RemoveSoftwareEquipment($equipment_id: bigint!) {
      delete_software_equipments(where: {equipment_id: {_eq: $equipment_id}}) {
        affected_rows
      }
    }
  `);

  const [cameraEquipmentAddMutation] = useMutation(gql`
    mutation AddCameraEquipment($equipment_id: bigint!, $camera_model: String!) {
      insert_camera_equipments(objects: {equipment_id: $equipment_id, camera_model: $camera_model}) {
        affected_rows
      }
    }
  `);

  const [softwareEquipmentAddMutation] = useMutation(gql`
    mutation AddSoftwareEquipment($equipment_id: bigint!, $name: String!) {
      insert_software_equipments(objects: {equipment_id: $equipment_id, name: $name}) {
        affected_rows
      }
    }
  `);

  const handleChange = (field: keyof EquipmentData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = field === 'isPublic' ? event.target.checked : event.target.value;
    setNewData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSave = async () => {
    if (!data.id) {
      await equipmentMutationInsert({
        variables: {
          user_id: userID,
          is_public: newData.isPublic,
          camera_equipments: { 
            data: newData.cameras.map(camera => ({ camera_model: camera.model }))
          },
          software_equipments: {
            data: newData.software.map(sw => ({ name: sw.name }))
          }
        }
      });
    } else {
      await equipmentMutationUpdate({
        variables: {
          id: data.id,
          is_public: newData.isPublic
        }
      });

      // Update cameras if changed
      if (data.cameras.sort().join(',') !== newData.cameras.sort().join(',')) {
        await cameraEquipmentRemoveMutation({
          variables: {
            equipment_id: data.id
          }
        });
        await Promise.all(
          newData.cameras.map(async (camera) => {
            if (camera.model) {
              await cameraEquipmentAddMutation({
                variables: {
                  equipment_id: data.id,
                  camera_model: camera.model
                }
              });
            }
          })
        );
      }

      // Update software if changed
      if (data.software.sort().join(',') !== newData.software.sort().join(',')) {
        await softwareEquipmentRemoveMutation({
          variables: {
            equipment_id: data.id
          }
        });
        await Promise.all(
          newData.software.map(async (sw) => {
            if (sw.name) {
              await softwareEquipmentAddMutation({
                variables: {
                  equipment_id: data.id,
                  name: sw.name
                }
              });
            }
          })
        );
      }

      // refresh the graphql cache
      window.location.reload();
    }
  };

  // Common camera models
  const commonCameraModels = [
    'Zenmuse H20T',
    'Zenmuse P1',
    'Zenmuse L1',
    'Phantom 4 RTK Camera'
  ];

  // Common software
  const commonSoftware = [
    'Pix4D',
    'DroneDeploy',
    'Agisoft Metashape',
    'Global Mapper',
    'DJI FlightHub',
    'ArcGIS'
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {t('pilot.fields.cameras')}
      </Typography>
      <Autocomplete
        multiple
        options={commonCameraModels}
        value={newData.cameras.map((camera) => camera.model)}
        onChange={(_event, newValue) => {
          setNewData(prevState => ({ ...prevState, cameras: newValue.map(model => ({ model })) }));
        }}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.cameraModel')}
            placeholder={t('pilot.placeholders.addCamera')}
          />
        )}
      />

      <Typography variant="h6" gutterBottom>
        {t('pilot.fields.software')}
      </Typography>
      <Autocomplete
        multiple
        options={commonSoftware}
        value={newData.software.map((sw) => sw.name)}
        onChange={(_event, newValue) => {
          setNewData(prevState => ({ ...prevState, software: newValue.map(name => ({ name })) }));
        }}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('pilot.fields.softwareName')}
            placeholder={t('pilot.placeholders.addSoftware')}
          />
        )}
      />

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default Equipment;
