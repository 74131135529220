import { ThemeProvider } from "@mui/material";
import React from "react";
import theme from "../theme";
import Menu, { MenuItems } from "./Menu";

const items: MenuItems[] = [
  {
    items: [
      { title: 'Dashboard', url: '/photogrammetry', default: true },
      { title: 'Inspections', url: '/photogrammetry/inspections', iframePath: '/app/inspections' },
    ]
  }
];

export interface PhotogrammetryMenuProps {
  open?: boolean;
  url?: string;
  iframeLocation?: string;
};

const PhotogrammetryMenu = (props: PhotogrammetryMenuProps) => {
  const [ menuItems, setMenuItems ] = React.useState<MenuItems[]>(items);

  React.useEffect(() => {
    if (!props.iframeLocation || !props.iframeLocation.includes('photogrammetry')) {
      return;
    }

    const url = new URL(props.iframeLocation);
    let found = false;
    const items = [...menuItems];
    for (const item of items[0].items) {
      if (item.default) {
        item.active = false;
        continue;
      }
      if (item.iframePath && url.pathname.includes(item.iframePath)) {
        item.active = true;
        found = true;
      } else {
        item.active = false;
      }
    }
    if (!found) {
      items[0].items.filter(item => item.default).forEach(item => item.active = true);
    }
    setMenuItems(items);
  }, [props.iframeLocation]);

  return (
    <ThemeProvider theme={theme}>
      <Menu
        theme={theme}
        open={props.open ?? false}
        title="Photogrammetry"
        items={items}
        url={props.url} />
    </ThemeProvider>
  );
};

export default PhotogrammetryMenu;
