import React from "react";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import EnhancedTable from "../components/EnhancedTable";
import { Button, Menu, MenuItem, Typography, Fab, ListItemIcon, ListItemText } from "@mui/material";
import DrawIcon from '@mui/icons-material/Draw';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import AddIcon from '@mui/icons-material/Add';
import FlightPlan from "../classes/flightPlan";
import { Link, useNavigate } from "react-router-dom";
import { darkGray } from "../theme";
import SelectAreaDialog from "../components/dialogs/SelectAreaDialog";
import { useTranslation } from "react-i18next";

const headCells = [
  // { id: 'uuid', numeric: false, disablePadding: false, label: 'UUID' }, 
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Updated At' },
  { id: 'menu', numeric: false, disablePadding: false, label: '', unsortable: true },
];

const Plans = () => {
  const [selectAreaDialogOpen, setSelectAreaDialogOpen] = React.useState(false);
  const [fps, setFps] = React.useState<FlightPlan[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const fabRef = React.useRef<HTMLButtonElement>(null);
  const menuOpen = Boolean(anchorEl);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query FlightPlans {
      flight_plans(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {name: asc}) {
        id
        uuid
        name
        created_at
        updated_at
        drones {
          uuid
        }
      }
    }
  `));

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(fabRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (data) {
      setFps(data.flight_plans.map((fp: any) => {
        const f = new FlightPlan(fp.uuid, fp.name, fp.created_at, fp.updated_at);
        f.id = fp.id;
        f.dronesUUIDs = fp.drones.map((d: any) => d.uuid);
        f.navigate = navigate;
        return f;
      }));
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { fps.length > 0
      ?
      <EnhancedTable
        title="Fight Plans"
        headCells={headCells}
        defaultOrderBy="updated_at"
        defaultOrder="desc"
        rows={fps} />
      :
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No flight plans found')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleMenuOpen}
          style={{
            margin: '32px auto',
            display: 'block',
          }}>
          {t('Add FlightPlan')}
        </Button>
        <Typography variant="body2" style={{
          textAlign: 'center',
          color: darkGray,
        }}>
          {t('or')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '16px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }

      <Fab
        color="primary"
        aria-label={t('Add FlightPlan')}
        onClick={(event) => handleMenuOpen(event)}
        ref={fabRef}
        style={{
          position: 'absolute',
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: '-8px', // Move the anchor a little bit over
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setSelectAreaDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <Grid3x3Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Existing Area')}</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={"new/area"}
          onClick={handleMenuClose}
        >
          <ListItemIcon>
            <ArchitectureIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('New Area')}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled
          onClick={handleMenuClose}
        >
          <ListItemIcon>
            <DrawIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Linear')}</ListItemText>
        </MenuItem>
      </Menu>

      <SelectAreaDialog
        open={selectAreaDialogOpen}
        onClose={(field) => {
          setSelectAreaDialogOpen(false);
          if (field) {
            navigate('new/area/'+field.uuid);
          }
        }}
      />
    </React.Fragment>
  );
};

export default Plans;
