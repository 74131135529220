import React from 'react';
import { 
  Box, 
  TextField, 
  FormControlLabel, 
  Switch, 
  Chip,
  Autocomplete,
  MenuItem,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExperienceData } from '../../../classes/PilotProfile';
import { gql, useMutation } from '@apollo/client';
import useUser from '../../../common/hooks/useUser';

interface ExperienceProps {
  data: ExperienceData;
}

const Experience: React.FC<ExperienceProps> = ({ data }) => {
  const [newData, setNewData] = React.useState(data);
  const { userID } = useUser();
  const { t } = useTranslation();

  const [experienceMutationUpdate] = useMutation(gql`
    mutation UpdateExperience(
      $id: bigint!,
      $is_public: Boolean,
      $mission_count: String,
      $years_of_experience: String
    ) {
      update_experiences_by_pk(pk_columns: {id: $id}, _set: {
        is_public: $is_public,
        mission_count: $mission_count,
        years_of_experience: $years_of_experience
      }) {
        id
      }
    }
  `);

  const [experienceMutationInsert] = useMutation(gql`
    mutation InsertExperience(
      $user_id: bigint!,
      $is_public: Boolean,
      $mission_count: String,
      $years_of_experience: String,
      $specializations: specializations_arr_rel_insert_input
    ) {
      insert_experiences(objects: {
        user_id: $user_id,
        is_public: $is_public,
        mission_count: $mission_count,
        years_of_experience: $years_of_experience,
        specializations: $specializations
      }) {
        returning {
          id
        }
      }
    }
  `, {
    refetchQueries: ['UserProfiles']
  });

  const [specializationsRemoveMutation] = useMutation(gql`
    mutation RemoveSpecializations($experience_id: bigint!) {
      delete_specializations(where: {experience_id: {_eq: $experience_id}}) {
        affected_rows
      }
    }
  `);

  const [specializationsAddMutation] = useMutation(gql`
    mutation AddSpecialization($experience_id: bigint!, $name: String!) {
      insert_specializations(objects: {experience_id: $experience_id, name: $name}) {
        affected_rows
      }
    }
  `);

  const handleChange = (field: keyof ExperienceData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = field === 'isPublic' ? event.target.checked : event.target.value;
    setNewData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSpecializationsChange = (_event: React.SyntheticEvent, newValue: string[]) => {
    setNewData(prevState => ({ ...prevState, specializations: newValue }));
  };

  const handleSave = async () => {
    if (!data.id) {
      await experienceMutationInsert({
        variables: {
          user_id: userID,
          is_public: newData.isPublic,
          mission_count: newData.missionCount,
          years_of_experience: newData.yearsOfExperience,
          specializations: {
            data: newData.specializations.map(spec => ({ name: spec }))
          }
        }
      });
    } else {
      await experienceMutationUpdate({
        variables: {
          id: data.id,
          is_public: newData.isPublic,
          mission_count: newData.missionCount,
          years_of_experience: newData.yearsOfExperience
        }
      });

      // Update specializations if changed
      if (data.specializations.sort().join(',') !== newData.specializations.sort().join(',')) {
        await specializationsRemoveMutation({
          variables: {
            experience_id: data.id
          }
        });
        await Promise.all(
          newData.specializations.map(async (spec) => {
            await specializationsAddMutation({
              variables: {
                experience_id: data.id,
                name: spec
              }
            });
          })
        );
      }

      // refresh the graphql cache
      window.location.reload();
    }
  };

  // Experience ranges
  const experienceRanges = [
    '0-1',
    '1-2',
    '2-3',
    '3-4',
    '4-5',
    '5+'
  ];

  // Mission count ranges
  const missionRanges = [
    '0-10',
    '11-25',
    '26-50',
    '51-100',
    '100+'
  ];

  // Common specializations
  const commonSpecializations = [
    'Aerial photogrammetry',
    'Surveillance',
    'Emergency response',
    'Power lines',
    'Photovoltaic',
    'Agriculture',
    'Construction',
    'Real estate',
    'Film and media',
    'Search and rescue',
    'Industrial inspection'
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <TextField
        select
        label={t('pilot.fields.yearsOfExperience')}
        value={newData.yearsOfExperience}
        onChange={handleChange('yearsOfExperience')}
        required
        fullWidth
      >
        {experienceRanges.map((range) => (
          <MenuItem key={range} value={range}>
            {range} {t('pilot.fields.years')}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label={t('pilot.fields.missionCount')}
        value={newData.missionCount}
        onChange={handleChange('missionCount')}
        required
        fullWidth
      >
        {missionRanges.map((range) => (
          <MenuItem key={range} value={range}>
            {range} {t('pilot.fields.missions')}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        options={commonSpecializations}
        value={newData.specializations}
        onChange={handleSpecializationsChange}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('pilot.fields.specializations')}
            placeholder={t('pilot.placeholders.addSpecialization')}
          />
        )}
        freeSolo
      />

      <FormControlLabel
        control={
          <Switch
            checked={newData.isPublic}
            onChange={handleChange('isPublic')}
          />
        }
        label={t('pilot.fields.publicProfile')}
      />

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSave}
        sx={{ mt: 2 }}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default Experience;
