import React from 'react';
import { Box, Stepper, Step, StepLabel, Typography, StepContent, useTheme, useMediaQuery, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonalInfo from './sections/PersonalInfo';
import Qualifications from './sections/Qualifications';
import Equipment from './sections/Equipment';
import Experience from './sections/Experience';
import Availability from './sections/Availability';
import { PilotProfile } from '../../classes/PilotProfile';

interface PilotStepperProps {
  activeStep: number;
  onStepChange: (step: number) => void;
  showContent?: boolean;
  profile: PilotProfile;
  onProfileChange: (section: string, data: any) => void;
}

const PilotStepper: React.FC<PilotStepperProps> = ({ 
  activeStep, 
  onStepChange,
  showContent = false,
  profile,
  onProfileChange
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = [
    {
      key: 'personalInfo',
      label: t('pilot.sections.personalInfo'),
      description: t('pilot.descriptions.personalInfo'),
      icon: <PersonIcon />,
      content: <PersonalInfo 
        data={profile.getPersonalInfo()} 
      />
    },
    {
      key: 'qualifications',
      label: t('pilot.sections.qualifications'),
      description: t('pilot.descriptions.qualifications'),
      icon: <WorkIcon />,
      content: <Qualifications 
        data={profile.getQualifications()} 
      />
    },
    {
      key: 'equipment',
      label: t('pilot.sections.equipment'),
      description: t('pilot.descriptions.equipment'),
      icon: <BuildIcon />,
      content: <Equipment 
        data={profile.getEquipment()}
      />
    },
    {
      key: 'experience',
      label: t('pilot.sections.experience'),
      description: t('pilot.descriptions.experience'),
      icon: <StarIcon />,
      content: <Experience 
        data={profile.getExperience()} 
      />
    },
    {
      key: 'availability',
      label: t('pilot.sections.availability'),
      description: t('pilot.descriptions.availability'),
      icon: <AccessTimeIcon />,
      content: <Availability 
        data={profile.getAvailability()} 
      />
    }
  ];

  return (
    <Box sx={{ 
      maxWidth: isMobile ? '100%' : 400,
      '& .MuiStepContent-root': {
        marginLeft: 2,
        borderLeft: `1px solid ${theme.palette.divider}`,
        paddingLeft: 2,
        marginBottom: 2
      },
      '& .MuiStepLabel-root': {
        cursor: 'pointer',
      },
      '& .MuiStepLabel-iconContainer': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.disabled,
        },
        '&.Mui-active .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        }
      },
      '& .MuiStepLabel-label': {
        '&.Mui-active': {
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        }
      }
    }}>
      <Stepper 
        activeStep={activeStep} 
        orientation="vertical"
        sx={{
          '& .MuiStepConnector-line': {
            borderColor: theme.palette.divider
          },
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main
          }
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.key}>
            <StepLabel
              icon={step.icon}
              onClick={() => onStepChange(index)}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: activeStep === index ? 'bold' : 'normal'
                  }}
                >
                  {step.label}
                </Typography>
                <Collapse in={activeStep === index} timeout="auto">
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 0.5,
                      color: 'primary.main'
                    }}
                  >
                    {step.description}
                  </Typography>
                </Collapse>
              </Box>
            </StepLabel>
            {isMobile && showContent && (
              <StepContent>
                <Box sx={{ pb: 2 }}>
                  {activeStep === index && step.content}
                </Box>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default PilotStepper;
