import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface SelectAltitudeProps {
  defaultAltitude: number;
  open: boolean;
  onClose: (altitude?: number) => void;
}

const SelectAltitudeDialog = (props: SelectAltitudeProps) => {
  const [ value, setValue ] = React.useState<number>(props.defaultAltitude);

  const { open, onClose } = props;

  const { t } = useTranslation();

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const marks = [
    { value: 25, label: '25m' },
    { value: 50, label: '50m' },
    { value: 75, label: '75m' },
    { value: 100, label: '100m' },
    { value: 120, label: '120m' }
  ];
  
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>{t('Select Altitude')}</DialogTitle>
      <DialogContent>
        <Slider
          style={{ marginTop: 40 }}
          value={value}
          onChange={handleChange}
          aria-labelledby="altitude-slider"
          valueLabelDisplay="on"
          step={1}
          min={25}
          max={120}
          marks={marks}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <Button onClick={() => {
          if (!value) {
            return;
          }
          onClose(value);
        }} autoFocus disabled={!value}>
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectAltitudeDialog;
